import React from 'react'

const Banner = ({ title, info }) => {
  return (
    <div className="banner">
      <h1>{title}</h1>
      <p>{info}</p>
      <a className="button" href="/">
        BACK TO HOME PAGE
      </a>
    </div>
  )
}

export default Banner
